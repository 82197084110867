// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rc d_gs d_cp";
export var heroHeaderCenter = "s_gt d_gt d_cp d_ds";
export var heroHeaderRight = "s_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "s_rd d_gp d_cs";
export var heroParagraphCenter = "s_gq d_gq d_cs d_ds";
export var heroParagraphRight = "s_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "s_rf d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "s_rg d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "s_rh d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "s_rj d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "s_rk d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "s_rl x_rl";
export var heroExceptionNormal = "s_rm x_rm";
export var heroExceptionLarge = "s_rn x_rn";
export var Title1Small = "s_rp x_rp x_rM x_rN";
export var Title1Normal = "s_rq x_rq x_rM x_rP";
export var Title1Large = "s_rr x_rr x_rM x_rQ";
export var BodySmall = "s_rs x_rs x_rM x_r4";
export var BodyNormal = "s_rt x_rt x_rM x_r5";
export var BodyLarge = "s_rv x_rv x_rM x_r6";