// extracted by mini-css-extract-plugin
export var customText = "p_qn d_dr d_cp d_cc";
export var videoIframeStyle = "p_pH d_d2 d_s d_D d_bv d_bY d_N";
export var customImageWrapper = "p_qp d_cp d_cc d_W";
export var customRow = "p_qq d_bz d_bb";
export var quoteWrapper = "p_gN d_by d_bL d_cp d_cc";
export var quoteBar = "p_pM d_D";
export var masonryImageWrapper = "p_pT";
export var title = "p_qr";
export var Title3Small = "p_qs x_qs x_rM x_rV";
export var Title3Normal = "p_qt x_qt x_rM x_rW";
export var Title3Large = "p_qv x_qv x_rM x_rX";