// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fM d_fM d_bw d_bD";
export var navbarDividedRight = "y_fN d_fN d_bw";
export var menuLeft = "y_sR d_fK d_bw d_ds d_bL";
export var menuRight = "y_sS d_fK d_bw d_ds d_bL";
export var menuCenter = "y_sT d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "y_n8 d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "y_n9 d_bt";
export var navbarLogoItemWrapper = "y_fV d_fV d_by d_bL";
export var burgerToggle = "y_sV d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "y_sW d_f8 d_bv d_bp d_0";
export var burgerInput = "y_sX d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "y_sY d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "y_sZ d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "y_s0 d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "y_s1 d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "y_s2 d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "y_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "y_s3 d_bY d_cz d_dx";
export var icon = "y_p0";
export var secondary = "y_s4 d_by d_bL";