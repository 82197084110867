// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_qJ d_fl d_bC d_dr";
export var alignLeft = "r_p5 d_fl d_bC d_dr";
export var alignDiscCenter = "r_qK d_fm d_bz d_ds";
export var alignCenter = "r_bL d_fm d_bz d_ds";
export var alignDiscRight = "r_qL d_fn d_bD d_dt";
export var alignRight = "r_p6 d_fn d_bD d_dt";
export var footerContainer = "r_qM d_dS d_bS";
export var footerContainerFull = "r_qN d_dQ d_bS";
export var footerHeader = "r_kc d_kc";
export var footerTextWrapper = "r_qP d_s";
export var footerDisclaimerWrapper = "r_kk d_kk d_cg";
export var badgeWrapper = "r_qQ d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "r_kl d_kl d_bw";
export var footerDisclaimerLeft = "r_km d_km d_bw";
export var verticalTop = "r_qR d_bw d_bF d_bK d_bH";
export var firstWide = "r_qS";
export var disclaimer = "r_qT";
export var socialDisclaimer = "r_qV";
export var left = "r_qW";
export var wide = "r_qX d_cv";
export var right = "r_qY d_bG";
export var line = "r_fg d_fh d_cr";
export var badgeDisclaimer = "r_qZ d_by d_bL d_bF";
export var badgeContainer = "r_q0 d_bw d_bD d_bL";
export var badge = "r_q1";
export var padding = "r_q2 d_c4";
export var end = "r_q3 d_bD";
export var linkWrapper = "r_q4 d_dx";
export var link = "r_mF d_dx";
export var colWrapper = "r_q5 d_ct";
export var media = "r_q6 d_bt d_dv";
export var itemRight = "r_q7";
export var itemLeft = "r_q8";
export var itemCenter = "r_q9";
export var exceptionWeight = "r_rb x_sc";